@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;700&display=swap');

:root {
  --main-grey: #1f1f2b;
  --primary-color: #2b2bce;
}

body {
  font-family: 'IBM Plex Mono', monospace;
  background-color: #f5f5f5;
  color: var(--main-grey);
}

h1 {
  font-family: 'IBM Plex Mono', monospace;
}

.font-blue{
  color: var(--primary-color);
  font-family: 'IBM Plex Mono', monospace;
}

.card {
  height: 350px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background-color: var(--main-grey);
}

.card:hover {
  outline: 5px solid var(--primary-color);
  color: var(--primary-color);
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.card-content {
  position: relative;
  z-index: 2;
  color: white;
  text-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add-new {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 2px dashed var(--main-grey);
}

.add-new:hover {
  border: 2px solid var(--primary-color);
}

button {
  font-family: 'IBM Plex Mono', monospace;
}


@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;700&display=swap');

body {
  font-family: 'IBM Plex Mono', monospace;
  background-color: #f5f5f5;
  color: #1f1f2b;
}

h1 {
  font-family: 'IBM Plex Mono', monospace;
}

.card {
  height: 350px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background-color: #1f1f2b;  
}

.card:hover {
  outline: 5px solid #2b2bce;
  color: #2b2bce;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.card-content {
  position: relative;
  z-index: 2;
  color: white;
  text-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add-new {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 2px solid #1f1f2b;
}

.add-new:hover {
  border: 2px solid #2b2bce;
}


.rotating-emoji {
  font-size: 2rem;
  display: inline-block;
  animation: rotation 1.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}